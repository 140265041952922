.table-container {
    width: 100%;
    /* border-radius: 30px; */
    margin: auto;
    overflow-x: hidden;
    /* padding: 33px 4% 4% 4%; */
    padding: 0px 4% 0% 4%;
    min-width: 1100px;
  }

@media screen and (min-height:800px) {
  .table-dialog-main{
    overflow-y: hidden !important;
  }  
}

@media screen and (max-width:750px) {

    .table-container{
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin: auto;
    }
    
}

.top-left-radius{
    border-top-left-radius: 12px;
}

.top-right-radius{
    border-top-right-radius: 12px;
}

.bottom-left-radius{
    border-bottom-left-radius: 12px;
}

.bottom-right-radius{
    border-bottom-right-radius: 12px;
}

  
  .custom-table {
    border-radius: 30px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

  }
  
  .custom-table td {
    border: 0.5px solid darkgray;
    padding: 9px;
    text-align: center;
  }
  
  .column-1 {
    background-color: black;
    color: white;
    font-size: 13px;
    font-weight: bold;
    width:130px;
    min-width: 130px;
    height: 60px

  }
  
  .column-2 {
    color: black;
    background-color: #fcf9ee;
    font-weight: bold;
    font-size: 13px;
    width: 280px;
    min-width: 280px;
    height: 60px

  }
 
  .column-3 {
    color: black;
    background: #fcf9ee;
    font-weight: bold;
    font-size: 13px;
    width: 280px;
    min-width: 280px;
    height: 60px;
  }


  .column-2-ar {
    color: black;
    background-color: #fcf9ee;
    font-weight: bold;
    font-size: 13px;
    width:480px;
    height: 60px;
  }

  .column-3-ar {
    color: black;
    background: #fcf9ee;
    font-weight: bold;
    font-size: 13px;
    width:480px;
    height: 60px;
  }
    
  .orange-keyword {
    color: #fe6100 !important;
    font-size: x-large !important;
    font-weight: 700;
    height: 85px;
  }    
  .plan-table-custom-button {
    display: inline-block;
    height: 35px;
    line-height: 12.5px;
    padding: 10px 20px;
    border: 2px solid #fe6100;
    border-radius: 30px;
    color: #fe6100;
    cursor: pointer;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    /* width: 95%; */
  }
  
  .plan-table-custom-button:hover {
    background-color: #fe6100;
    color: white;
  }
  

.current-plan{
  border-radius: 30px;
  display: inline-block;
  padding: 4.5px 20px;
  border: 2px solid #fe6100;
  background-color: #fe6100;
  color: white;
  position: sticky;
  font-size: medium;
  height: 35px;
}

.trial-plan{
    top:4%;
    left:29.5%;
}

.enterprise-plan{
  top:4%;
  left:68.5%;
}

.module-use-info-div{
  display: flex;
}

@media screen and (max-width:600px){
  .module-use-info-div{
    display: block;
    margin-top: 2%;
  }

}

