.rightSideWrapper {
  flex: 1;
  // height: 100vh;
  // overflow-y: auto; 
  // overflow-x: hidden;
  overflow: hidden;
  display: flex;
  // border: 2px solid red;
  // max-width: fit-content;
  // margin-left: 0%;
  // padding-left: 3%;
}

.orange-bg-btn-transition {
  transition: all 0.3s ease;

  &:hover {
    background-color: #fe6100;
    color: white;

    .orange-bg-btn-text {
      color: white;
    }
  }
}




.DropdownWrapperBox {
  max-width: 55vw;
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
}
.dashboardLayoutWrapper {
  // background-color: var(--white);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top 190px left 50px;
  display: flex;
  // height: 100vw; // causes long overflow on advise, process, analyse modules
  overflow: hidden;
  @media screen and (max-width:750px) {

    .mainWrapper {
      margin-left: 0% !important;
      // margin-left: 113px;
      overflow: hidden;
      height: 100%;
      width: 100%;
      padding: 2%;
      flex-direction: column;
      display: flex;
      // overflow-y: auto;
      // height: 100vh;

      :global {

        .nav-tabs {
          align-items: center;
          justify-content: center;
          gap: 1rem;
          padding: 15px;
          // margin-bottom: 0 !important;
          display: flex;
          padding: 6px;
          border-radius: 49px;
          // background: var(--soft-yellow-100);
          gap: 16px;
          max-width: 243px;
          // margin-left: 30% !important; 

          margin-top: 26px;
          .nav-item {
            .nav-link {
              z-index: 3;
              border-radius: 8px;
              // border: 1px solid var(--gray-200) !important;
              color: var(--gray-800);
              font-family: "Karla", sans-serif;
              border-radius: 8px;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              padding: 12px 16px;
              text-align: center;
              border-radius: 100%;
              border: none;
              // height: 40px;
              // width: 40px;
              padding: 19px;
              // height: 48px;
              position: relative;
              &{
                svg {
                  path,
                  rect {
                    fill: grey;
                    stroke: grey; // Change this to the desired color
                  }
                }
              }
              &:hover {
                div {
                  display: inline-flex !important;
                  // color:black sets color of text i.e process, advise, analyse
                }
                svg {
                  path,
                  rect {
                    fill: black;
                    stroke: black; // Change this to the desired color
                  }
                }
              }
  
              &.active {
                // background-color: var(--soft-yellow-600-main) !important;
                color: white;
                border-color: currentColor;
                svg {
                  path,
                  rect {
                    stroke: var(--white); /*color fill of SVG on selected*/
                    fill: rgb(150, 70, 70)
                  }
                }
              }
  
              &.active {
                // background-color: var(--soft-yellow-600-main) !important;
                color: rgb(102, 43, 43);
                border-color: currentColor;
                svg {
                  path,
                  rect {
                    stroke: var(--white); /*color fill of SVG on selected*/
                  }
                }
              }
            }
          }
        }


      }
    }
  }
  
  .mainWrapper {
    // margin-left: -9%;
    // margin-left: 113px;
    // overflow: hidden;
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    // overflow-y: auto;
    // height: 100vh;
    :global {
      .nav-tabs {
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 15px;
        // margin-bottom: 0 !important;
        display: flex;
        padding: 6px;
        border-radius: 50px;
        // background: var(--soft-yellow-100);
        gap: 16px;
        max-width: 243px;
        margin: auto;
        margin-top: 26px;
        .nav-item {
          .nav-link {
            z-index: 3;
            border-radius: 8px;
            // border: 1px solid var(--gray-200) !important;
            color: var(--gray-800);
            font-family: "Karla", sans-serif;
            border-radius: 8px;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 16px;
            text-align: center;
            border-radius: 100%;
            border: none;
            // height: 40px;
            // width: 40px;
            padding: 19px;
            // height: 48px;
            position: relative;
            &{
              svg {
                path,
                rect {
                  fill: grey;
                  stroke: grey; // Change this to the desired color
                }
              }
            }
            &:hover {
              div {
                display: inline-flex !important;
                height: 33.5px;
                // color:black sets color of text i.e process, advise, analyse
              }
              svg {
                path,
                rect {
                  fill: black;
                  stroke: black; // Change this to the desired color
                }
              }
            }

            &.active {
              // background-color: var(--soft-yellow-600-main) !important;
              color: white;
              border-color: currentColor;
              svg {
                path,
                rect {
                  stroke: var(--white); /*color fill of SVG on selected*/
                  fill: white
                }
              }
            }

            &.active {
              // background-color: var(--soft-yellow-600-main) !important;
              color: white;
              border-color: currentColor;
              svg {
                path,
                rect {
                  stroke: var(--white); /*color fill of SVG on selected*/
                }
              }
            }
          }
        }
      }
    }
  }

  .mainWrapperInactiveTab{
    :global {
      .nav-tabs {
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 15px;
        // margin-bottom: 0 !important;
        display: flex;
        padding: 6px;
        border-radius: 50px;
        // background: var(--soft-yellow-100);
        gap: 16px;
        max-width: 243px;
        margin: auto;
        margin-top: 26px;
        .nav-item {
          .nav-link {
            z-index: 3;
            border-radius: 8px;
            // border: 1px solid var(--gray-200) !important;
            color: var(--gray-800);
            font-family: "Karla", sans-serif;
            border-radius: 8px;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 16px;
            text-align: center;
            border-radius: 100%;
            border: none;
            // height: 40px;
            // width: 40px;
            padding: 19px;
            // height: 48px;
            position: relative;
            &{
              svg {
                path,
                rect {
                  fill: grey;
                  stroke: grey; // Change this to the desired color
                }
              }
            }
            &:hover {
              div {
                display: inline-flex !important;
                height: 33.5px
                // color:black sets color of text i.e process, advise, analyse
              }
              svg {
                path,
                rect {
                  fill: black;
                  stroke: black; // Change this to the desired color
                }
              }
            }

            &.active {
              // background-color: var(--soft-yellow-600-main) !important;
              color: grey !important;
              border-color: currentColor;
              svg {
                path,
                rect {
                  stroke: grey !important; /*color fill of SVG on selected*/
                  fill: grey !important;
                }
              }
            }

            
            &.active:hover {
              // background-color: var(--soft-yellow-600-main) !important;
              color: black !important;
              border-color: currentColor;
              svg {
                path,
                rect {
                  stroke: black !important; /*color fill of SVG on selected*/
                  fill: black !important;
                }
              }
            }

          }
        }
      }
    }
  }

  @media screen and (max-width:750px) {
    .mainWrapper {
      // margin-left: -8.5%;
      // margin-left: 113px;
      // overflow: hidden;
      height: 100%;
      width: 100%;
      flex-direction: column;
      display: flex;
      // overflow-y: auto;
      // height: 100vh;
      :global {
        .nav-tabs {
          align-items: center;
          justify-content: center;
          gap: 1rem;
          padding: 15px;
          // margin-bottom: 0 !important;
          display: flex;
          padding: 6px;
          border-radius: 50px;
          // background: var(--soft-yellow-100);
          gap: 16px;
          max-width: 243px;
          margin: auto;
          margin-top: 26px;
          .nav-item {
            .nav-link {
              z-index: 3;
              border-radius: 8px;
              // border: 1px solid var(--gray-200) !important;
              color: var(--gray-800);
              font-family: "Karla", sans-serif;
              border-radius: 8px;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              padding: 12px 16px;
              text-align: center;
              border-radius: 100%;
              border: none;
              // height: 40px;
              // width: 40px;
              padding: 19px;
              // height: 48px;
              position: relative;
              &{
                svg {
                  path,
                  rect {
                    fill: grey;
                    stroke: grey; // Change this to the desired color
                  }
                }
              }
              &:hover {
                div {
                  display: inline-flex !important;
                  // color:black sets color of text i.e process, advise, analyse
                }
                svg {
                  path,
                  rect {
                    fill: black;
                    stroke: black; // Change this to the desired color
                  }
                }
              }
  
              &.active {
                // background-color: var(--soft-yellow-600-main) !important;
                color: white;
                border-color: currentColor;
                svg {
                  path,
                  rect {
                    stroke: var(--white); /*color fill of SVG on selected*/
                    fill: white
                  }
                }
              }
  
              &.active {
                // background-color: var(--soft-yellow-600-main) !important;
                color: white;
                border-color: currentColor;
                svg {
                  path,
                  rect {
                    stroke: var(--white); /*color fill of SVG on selected*/
                  }
                }
              }
            }
          }
        }
      }
    }    
  }

}

.profileBtnWrapper {
  position: absolute;
  top: 30px;
  //right: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  .dropdown-menu {
    background-color: var(--white);
    border-radius: 8px;
  }

  button {
    width: 60px !important;
    height: 60px !important;
    border-radius: 100%;
    overflow: hidden;
    background-color: none;
    border: 1px solid var(--gray-500);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width:750px) {

  .profileBtnWrapper {
    top: 10%;
    // right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dropdown-menu {
      background-color: var(--white);
      border-radius: 8px;
    }
  
    button {
      width: 46px !important;
      height: 46px !important;
      border-radius: 100%;
      overflow: hidden;
      background-color: none;
      border: 1px solid var(--gray-500);
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  
}

.Tab-control-wrapper {
  height: 70px;
  border: 2px solid red;

  button {
    position: relative;
  }

  // li{
  //   margin-top:1px;
  // }
}

.chatToolTip {
  width: 110px;
  height: 42px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Karla;
  background: var(--mellow-blue-300-main);
  bottom: -50px;
  z-index: 9999999;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px, 8px, 4px, 8px;
  border-radius: 25px;
}

.chatToolTip::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--mellow-blue-300-main) transparent;
}

.uploadToolTip {
  width: 110px;
  height: 42px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Karla;
  position: absolute;
  background: #ffc107;
  color: white;
  bottom: -50px;
  z-index: 9999999;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px, 8px, 4px, 8px;
  border-radius: 18px;
}

.uploadToolTip::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffc107 transparent;
}

.analyzeToolTip {
  width: 110px;
  height: 42px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Karla;
  position: absolute;
  background: #78cd32;
  color: white;
  bottom: -50px;
  z-index: 9999999;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px, 8px, 4px, 8px;
  border-radius: 18px;
}

.analyzeToolTip::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #78cd32 transparent;
}
.chatToolTip{
  color:white !important
}

.chatToolTip,
.analyzeToolTip,
.uploadToolTip {
  display: none;
}

@media screen and (min-width:750px){
  .chatbot-layout-margin-en{
    margin-left: 110px;
  }

  .chatbot-layout-margin-ar{
    margin-right: 110px;
  }
}