.sidenavWrapper {
  border-radius: 0px 0px 0px 0px; // OLD: border-radius: 0px 0px 90px 0px;
  overflow: hidden;
  display: flex;
}
.noBorderRadius {
  border-radius: 0 !important;
}

.noBorderRadius {
  border-radius: 0 !important;
}

.sidebarMenuWrapper {
  // position: fixed;
  // background-color: var(--charcoal-100);
  height:100vh;
  // min-height: 640px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 288px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  padding: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 110px; // width of sidebar
  padding: 0px 20px 0px 20px;

  border: 0px 1px 0px 0px;
  gap: 10px;

  .topSection {
    position: relative;
    padding-top: 113px;

    .logo {
      padding: 0 0 48px 0;
    }
    .ItemList {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

@media screen and (max-width:750px) {

  .sidebarMenuWrapper {

    background-color: inherit;
    z-index: 100;
    position: fixed;
    
    height: 100vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    width: 288px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  
    padding: 24px;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 110px; // width of sidebar
    padding: 0px 20px 20px 20px;
  
    border: 0px 1px 0px 0px;
    gap: 10px;
  
    .topSection {
      position: relative;
      padding-top: 113px;
  
      .logo {
        padding: 0 0 48px 0;
      }
      .ItemList {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
  
}

.collapseWraper {
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-self: flex-start;
  align-items: center;
  border-radius: 50px;
}
