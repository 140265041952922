@media screen and (max-width:750px) {
    .main-simpla-logo{
      display: none !important;
    }

    .mobile-simpla-logo{
        display: flex !important;
    }
}

@media screen and (min-width: 750px) {
    .mobile-simpla-logo{
        display: none !important;
    }
}

.main-simpla-logo{
    z-index:20;
    width: fit-content;
    height:fit-content;
    cursor: pointer;
    margin-top: 25px;
    position: absolute;
}

.main-simpla-logo-en{
    margin-left: 120px !important;
}

.main-simpla-logo-ar{
    margin-right: 120px !important;
}

.mobile-simpla-logo{
    cursor: pointer;
}