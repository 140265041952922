

@media screen and (max-width:750px) {

      .desktop-profile-view{
        display: none;
      }
}


  
  .disclaimerbtn{
    width: 30%;
    margin-left: 1%;
    background: white !important; 
    color: black !important;
  }
  .disclaimerbtn:hover{
    background:white !important;
    color:black !important;

  }

  .custom-navbar{
    display: none !important;
  }
 
  .checkbox-text{
    font-size: large;
  }

  /* .chatmode-tabs{
    margin-left: 5%;
  } */
@media screen and (min-width:600px) {

  .disclaimer-checkbox-and-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%
  }
  
}

@media screen and (max-width:750px){

  .custom-navbar{
    display: block !important;
    height: 60px;
  }

   .switch-chatmode-heading{
    width:50%;
  }

  .checkbox-text{
    margin-top: 6%;
    font-size: normal;
  }

  .disclaimer-agree-button{
    margin-bottom: 4% !important ;
  }

  .disclaimer-checkbox-and-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0% auto;
  }
}

.more-uses-btn-text{
  font-size: large;
  color: #ff5b00;
  text-align: center !important;
  font-weight: 500 !important;
  /* padding-top: 5%; */

}

@media screen and (max-width: 400px){
  .more-uses-btn-text{
    font-size: 15px !important;
  }
}

@media screen and (min-width:400px) and (max-width: 430px){
  .more-uses-btn-text{
    font-size: 18px !important;
  }
}

@media screen and (max-width: 430px) {

  .more-uses-heading{
    font-size: x-large !important;
  }

  .more-uses-dialog-btn{
    width: 85% !important;
  }
}

.more-uses-heading{
  font-size: xx-large;
}

.more-uses-dialog-btn{
  width:100%;
  padding: 1%;
}

.more-uses-btn {
  cursor: pointer;
  border: 2px solid #ff5b00;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  max-height: 72.35px;
}

.close-circle{
  width: 30px;
  height: 30px;
  border: 1.2px solid black;
  border-radius: 50%;  
}

@media screen and (max-width:750px){

  .close-circle{
    top: 10%;
  }
}

.disclaimer-box .MuiTypography-root {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -.645888px;}

  .trial-plan-en{
    top: 3%;
    left: 20%;
  }

  .starter-plan-en{
    top: 3%;
    left: 48.5%;
  }

  .enterprise-plan-en{
    top: 3%;
    left: 49%;
  }

  .trial-plan-ar{
    top:3%;
    right: 21%;
  }

  .starter-plan-ar{
    top:3%;
    right: 50%;
  }

  .enterprise-plan-ar{
    top: 3%;
    right: 78.5%;
  }


  .Tab-control-wrapper li{
    margin-top:-1px;
  /* border:1px solid red; */
}

  @media screen and (min-width:750px){
    .chatbot-layout-margin-en{
      margin-left: 110px;
    }

    .chatbot-layout-margin-ar{
      margin-right: 110px;
    }
  }
