.dropdown-wrapper {
  .dropdown {
    .dropdown-menu.show {
      background-color: var(--white);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      border: none !important;
      padding: 8px;
      hr {
        margin: 8px 0px;
      }
      .dropdown-item {
        display: flex;
        justify-content: flex-start;
        align-items: center !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding: 4px;
        margin-bottom: 4px;
        gap: 8px;
        padding: 8px;

        svg {
          height: 20px;
          width: 20px;
        }
        .img-wrapper {
          width: 25px;
        
          overflow: hidden;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: auto;
            height: 25px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      a {
        color: black !important;
      }
    }
  }
}
.dropdown-item:focus {
  background-color: #f0ede4 !important; /* Use the color you want when the item is clicked or focused */
}