.cursor-pointer-function{
  cursor: pointer;
}

.AiChats {
  display: flex;
  flex-direction: column;
  .AiChat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      padding: 6px 8px;
      color: var(--gray-gray-hover);
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 125% */
      letter-spacing: -0.64px;
    }
    .filterbtn {
      padding: 4px !important;
      cursor: pointer;
      border-radius: 8px !important;
      &:hover {
        background-color: var(--white) !important;
      }
      &::after {
        display: none;
      }
    }
    .dropdown {
      .dropdown-menu {
        padding: 8px;
        .dropdown-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 8px;
          gap: 8px;
          border-radius: 8px;
        }
      }
    }
  }
  .form-control-wrapper {
    border-radius: 8px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    &:hover {
      background-color: var(--white);
      .dropdown-wrapper {
        display: block;
      }
    }
    .form-control-custom {
      padding: 8px 54px 8px 8px !important;
      text-overflow: ellipsis;
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.56px;
      width: 100%;
      overflow: hidden;
      background-color: transparent;
      border: none;
    }
    .dropdown-wrapper {
      display: none;
      // position: absolute;
      top: 0px;
      right: 0px;

      .dropdown-toggle::after {
        display: none;
      }
      .dropdown-menu.show {
        display: block;
        width: auto;
        background: var(--white);
        padding: 10px;
        border-radius: 8px;
        border: none !important;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        left: -100% !important;
      }
      .dropdown-menu-en .dropdown-menu.show{
        margin: 0px -55px !important;
      }
      .dropdown-item {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        font-family: Karla;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.64px;
        color: var(--gray-900) !important;
        border-radius: 8px;
        &:hover {
          // background-color: var(--gray-50) !important;
          // background: var(--gray-50) !important;
        }
      }
    }
    .icon-holder {
      display: none;
      position: absolute;
      top: 1.5px;
      height: 90%;
      right: 8px;
      background-color: var(--white);
      width: 60px;
      padding: 0px 8px;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      span {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .Renamed {
        svg {
          width: 18px;
          height: 18px;
          path {
            stroke: var(--bright-teal-600-main);
          }
        }
      }
      .Close {
        svg {
          width: 18px;
          height: 18px;
          path {
            stroke: var(--optimistic-orange-600-main);
          }
        }
      }
    }
    
    .icon-header-ar{
      right: calc(100% - 62px ) !important
    }
  }
}
